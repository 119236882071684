import * as Sentry from "@sentry/remix";
import "core-js/es/object/has-own";
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";
import "yet-another-react-lightbox/styles.css";
import posthog from "posthog-js";
import { ChatbotProvider } from "#client/chatbot/helpscout.tsx";

// @ts-ignore
const projectEnv = window.__remixContext.state.loaderData.root.projectEnv;

Sentry.init({
  dsn: "https://b97caaa3221a59e8fc10b0c1b64a5b92@o4507528996651008.ingest.us.sentry.io/4507528996913152",
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
  enabled: projectEnv === "production",
  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    Sentry.replayIntegration(),
  ],
});

// https://sergiodxa.com/articles/using-service-workers-with-remix
if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    // register zip download worker after complete page load
    navigator.serviceWorker.register("/zipWorker.js");
  });
}

function PosthogInit() {
  useEffect(() => {
    if (projectEnv === "production") {
      posthog.init("phc_4jAi0OZmd3xcytP4VthXEnXzRokGQCEHdEe4viNZPpr", {
        api_host: "https://who.eventshare.io",
        person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
      });
    }
  }, []);

  return null;
}

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <ChatbotProvider>
        <RemixBrowser />
      </ChatbotProvider>
      <PosthogInit />
    </StrictMode>,
  );
});
